<template>
  <div>
    <SectionHeader :title="headerTitle" title-icon="fal fa-stars" />
    <div class="box columns" v-if="vehiclesOnSale">
      <div class="field column is-narrow" v-if="vehiclesOnSale">
        <p class="is-size-7-mobile">Vehicles on sale</p>
        <p class="is-size-4 is-size-6-mobile has-text-primary">
          {{ formatNumber(vehiclesOnSale) || 0 }}
        </p>
      </div>
      <div class="field column is-narrow" v-if="daysInStock">
        <p class="is-size-7-mobile">Average days in stock</p>
        <p class="is-size-4 is-size-6-mobile has-text-primary">
          {{ formatNumber(daysInStock.average) || 0 }} days
        </p>
        <p
          class="tag is-light is-rounded"
          :class="`is-${daysInStockIcon.color}`"
        >
          <span v-if="daysInStockDiff">{{ daysInStockPastAverage }}</span>
          <span v-else>The same as last month</span>
          <span
            v-if="daysInStockDiff"
            class="icon is-small"
            :class="`has-text-${daysInStockIcon.color}`"
          >
            <i class="fas" :class="daysInStockIcon.icon" />
          </span>
        </p>
      </div>
      <div class="field column is-narrow" v-if="vehiclesSold">
        <p class="is-size-7-mobile">Sold in the last 30 days</p>
        <p class="is-size-4 is-size-6-mobile has-text-primary">
          {{ formatNumber(vehiclesSold.last30Days) }} vehicles
        </p>
        <p
          class="tag is-light is-rounded"
          :class="`is-${vehiclesSoldIcon.color}`"
        >
          <span>
            {{ formatNumber(vehiclesSold.previous30Days) }} sold in the previous 30 days
          </span>
          <span
            v-if="daysInStockDiff"
            class="icon is-small"
            :class="`has-text-${vehiclesSoldIcon.color}`"
          >
            <i class="fas" :class="vehiclesSoldIcon.icon" />
          </span>
        </p>
      </div>
    </div>
    <TableEmpty v-else text="No data found for your branch" size="small" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatNumber } from 'accounting'
export default {
  name: 'StockviewSalesOverview',
  components: {
    SectionHeader: () =>
      import('core/modules/section-header/components/SectionHeader'),
    TableEmpty: () => import('core/components/TableEmpty')
  },
  computed: {
    ...mapGetters('stockview/dashboard', [
      'vehiclesOnSale',
      'daysInStock',
      'vehiclesSold',
      'seller'
    ]),
    daysInStockDiff() {
      const { daysInStock } = this
      if (!daysInStock) return
      const { average, average30DaysAgo } = daysInStock
      return average - average30DaysAgo
    },
    daysInStockPastAverage() {
      const { daysInStockDiff } = this
      if (!daysInStockDiff) return
      const status = daysInStockDiff > 0 ? 'more' : 'less'
      const number = Math.abs(daysInStockDiff)
      return `${number} days ${status} than last month`
    },
    daysInStockIcon() {
      const { daysInStockDiff } = this
      const icon = daysInStockDiff > 0 ? 'down' : 'up'
      const color = daysInStockDiff > 0 ? 'danger' : 'success'
      return { icon: `fa-caret-${icon}`, color }
    },
    vehiclesSoldIcon() {
      const icon =
        this.vehiclesSold.previous30Days > this.vehiclesSold.last30Days ? 'down' : 'up'
      const color =
        this.vehiclesSold.previous30Days > this.vehiclesSold.last30Days
          ? 'danger'
          : 'success'
      return { icon: `fa-caret-${icon}`, color }
    },
    headerTitle() {
      if (this.$mq === 'mobile') return 'The big numbers'
      else return `The big numbers for ${this.seller?.name}`
    }
  },
  methods: {
    formatNumber
  }
}
</script>
